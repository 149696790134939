import { graphql, useStaticQuery } from 'gatsby'
import getImageHook from '../../utils/getImageHook'

type IFormat = 'webp' | 'png'

const getStaticImage = (filename: string, format: IFormat = 'png') => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: allImageSharp {
          edges {
            node {
              fluid {
                src
                srcWebp
              }
            }
          }
        }
      }
    `
  )

  const { src, srcWebp } = getImageHook(image, filename, 'fluid')
  const imgSrc = format === 'png' ? src : srcWebp
  return imgSrc
}

export default getStaticImage
